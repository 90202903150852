import {FolderData, GetFoldersDataResponse, Photo} from "../slices/apiSliceTypes";

export const parseList = (list: any[]) : any[] => {
  return list.map(item => item.folderName).sort();
}

const months = ['Unknown Month', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const colors = ["#E4938F", "#A29060", "#FFBE3C", "#7E265A", "#FF6E00", "#74A3B7", "#E05A34"];

const get_color = (index: number): string =>  {
  return index < 7 ? colors[index] : Math.random().toString(16).substr(-6);
}
const get_color_from_folder = (folderPath: string, folders: any[]): string => {
  if(folders == null || folderPath == null)
    return "#000000"
  for (const folder of folders) {
    if(folder.name == get_folder_name(folderPath))
      return folder.color;
  }
  return "#000000"
}

const add_image_protocol = (path: string)  => {
  return "image-protocol://getFile/" + path;
}

const get_capture_date = (exif_date: string) => {
  const date = exif_date.split(" ")[0].split(":")
  return date[2] + ' ' + get_month(Number(date[1])).slice(0, 3).toUpperCase() + ' ' +  date[0].slice(2, 4);
}

const get_capture_time = (exif_date: string) => {
  return exif_date.split(" ")[1]
}

function get_sort_number(exif_datetime: string) {
  if(exif_datetime == null)
    return Number.MAX_SAFE_INTEGER;
  const captureDate = exif_datetime.split(" ")[0].split(":")
  const captureDays = Number(captureDate[0]) * 366 + Number(captureDate[1]) * 31 + Number(captureDate[2])

  const captureTime = get_capture_time(exif_datetime).split(":")
  const captureSeconds = Number(captureTime[0]) * 3600 + Number(captureTime[1]) * 60 + Number(captureTime[2])

  const date = captureDays * 60 * 60 * 24 + captureSeconds
  return date;
}

export const image_date_sort = (image_1, image_2) => {
  if(image_1.date == null)
    return -1;
  if(image_2.date == null)
    return 1;
  return image_1.date < image_2.date ? -1 : 1;
}
export const removeImageProtocol = (path: string) => {
  return path.replace('image-protocol://getFile/', '');
}

export const get_month = (month: number) => {
  return months[month]
}

export const get_month_index = (month: string) => {
  return months.indexOf(month);
}

export const get_folder_name = (folderName: string) => {
  if(folderName == null)
    return null;
  return folderName.split('/').slice(-1)[0];
}

export const get_folder_year = (folderName: string) => {
  if(folderName == null)
    return null;
  return Number(folderName.split('/')[0]);
}

export const get_folder_month = (folderName: string) => {
  if(folderName == null)
    return null;
  return Number(folderName.split('/')[1]);
}

export const get_folder_path = (y: string, m: number, folder: string) => {
  return y + '/' + m.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false}) + '/' + folder;
}

export const get_folder_paths = (y: string, m: number, folders: string[]) => {
  return folders.map(folder => get_folder_path(y, m, folder))
}

export const parseMonths = (list: any[]) : any[] => {
  return list.sort((x, y) => x.folderName - y.folderName).map(item =>
      (
          {
            "thumbnail": add_image_protocol(item.thumbnail),
            "name": get_month(item.folderName),
            "count": item.count
          }
      )
  );
}

export const parseMeaningfulFolders = (list: any[]) : any[] => {
  return list.filter((item) => item.folderName != null)
    .map((item, index) => (
      {
        "thumbnail": add_image_protocol(item.thumbnail),
        "name": get_folder_name(item.folderName),
        "color": get_color(index),
        "id": item.folderName
      }))
    .sort((x, y) => x.name < y.name ? -1 : 1);
}

export const parsePhotosList= (list: any[], folders: any[]) : any[] => {
  return list.map(item => (
      {
        "capture_date_day": item.exif_datetime != null ? get_capture_date(item.exif_datetime): "",
        "capture_date_time": item.exif_datetime != null ? get_capture_time(item.exif_datetime): "",
        "thumbnail": add_image_protocol(item.file_path),
        "similar_paths": item.sim_file_paths != undefined ? item.sim_file_paths : [],
        // "color": get_color_from_folder(item.mnm_group, folders),
        "folder": item.mnm_group,
        "date": get_sort_number(item.exif_datetime),
        "wb_group": get_folder_name(item.wb_group),
        "char_labels": item.char_labels != undefined ? item.char_labels.map((e) => e.char_label) : []
      }
  ));
}

export const parseSimilarPhotos = (list: any[]) : any[] => {
  return list.map(item => (
      {
        "thumbnail": add_image_protocol(item)
      }
  ));
}


export const parseCompilationsList= (list: any[]) : any[] => {
  return list.map((item, index) => (
    {
      "compilationId": item.compil_id,
      "name": item.compil_name,
      "thumbnail": add_image_protocol(item.photos_list[0]),
      "photosPaths": item.photos_list.map(item => add_image_protocol(item)),
      "color": get_color(index),
    }
  ));
}


export const parseNonMemories = (list: any[]) : any[] => {
  return list.filter(item => item.folderName != null).map(item => (
      {
        "thumbnail": add_image_protocol(item.thumbnail),
        "name": item.folderName,
        "count": item.count
      }
  )).sort((x, y) => x.name < y.name ? -1 : x.name > y.name ? 1 : 0 );
}

export const parseMemoriesNonMemoriesFromTree = (tree: any) : any[] => {
  return tree
            .children
            .filter(item => item.name == 0 || item.name == 1)
            .map(item => ({name: item.name, thumbnail: add_image_protocol(item.thumbnail)}))
            .sort((x, y) => x.name < y.name ? -1 : 1)
}

export const parseYearsFromTree = (tree: any) : any[] => {
  return tree
            .children
            .map(item => ({name: item.name, thumbnail: add_image_protocol(item.thumbnail)}))
            .filter(item => item.name !=  null)
            .sort((x, y) => x.name < y.name ? -1 : 1)
}


export const parseMonthsFromFoldersData = (foldersData: GetFoldersDataResponse) => {
  const months = [];

  for (const month of Object.keys(foldersData)) {
    const thumbnail = add_image_protocol(foldersData[month][0].thumbnail);

    months.push({ name: get_month(Number(month)), thumbnail: thumbnail });
  }

  return months;
}

export const parseFoldersFromFoldersData = (folderData: FolderData[]): FolderData[] => {
  const parsedFolders = folderData.map(folder => ({
    ...folder,
    thumbnail: add_image_protocol(folder.thumbnail)
  }));

  return parsedFolders;
};

export const parseMonthsFromTree = (tree: any, year: number) : any[] => {
  const sorted = tree
            .children
            .filter(item => item.name == year)[0]
            .children
            .slice()
  sorted.sort((x, y) => x.name < y.name ? -1 : 1)
  return sorted.map(item => ({name: get_month(item.name), thumbnail: add_image_protocol(item.thumbnail)}))
}

export const parseFoldersFromTree = (tree: any, year: number, month: string) : any[] => {
  const sorted = tree
            .children
            .filter(item => item.name == year)[0]
            .children
            .filter(item => item.name == get_month_index(month))[0]
            .children
            .filter(item => item.name != null)
            .map(item => ({name: get_folder_name(item.name), thumbnail: add_image_protocol(item.thumbnail)}))
            .slice()
  sorted.sort();
  return sorted
}

export const parseTopicsFromTree = (tree: any) : any[] => {
  var topics = [];
  const years = tree.children.filter(item => item.name == 1)[0].children.map(item => item.name)
  for (const year of years) {
    const months = tree.children.filter(item => item.name == 1)[0].children.filter(item => item.name == year)[0].children.map(item => get_month(item.name));
    for (const month of months) {
      const folders = tree.children.filter(item => item.name == 1)[0].children.filter(item => item.name == year)[0].children.filter(item => item.name == get_month_index(month))[0].children;
      for (const topic of folders)
        topics.push({name: topic.name, thumbnail: add_image_protocol(topic.thumbnail)})
    }
  }
  return topics.filter(function(elem, index, self) {
    return index === self.indexOf(elem);
  })
}

export const formatNumber = (x: number) => {
  return Intl.NumberFormat("en-US", { }).format(x) + ' ';
}

export const isJsonEmpty = (json) => {
  for (const prop in json) {
    if (Object.hasOwn(json, prop)) {
      return false;
    }
  }
  return true;
}

export const filterNull = (list: [any]) => {
  return list.filter(item => item != null)
}

