import { createSlice } from "@reduxjs/toolkit";
export interface Account {
  account: {
    customerId: number,
    email: string,
    name: string;
  },
  unlock: {
    senderId: number;
    compilId: number;
  }
}

const initialState: Account = {
  account: {
    customerId: -1,
    email: "",
    name: ""
  },
  unlock: {
    senderId: -1,
    compilId: -1
  }
}
export const accountSlice = createSlice({
  name: "accountSlice",
  initialState,
  reducers: {
    setAccount: (state, action) => {
      state.account = action.payload;
    },
    setUnlock: (state, action) => {
      state.unlock = action.payload;
    },
    logOut: () : Account => {
      return initialState;
    },
  },
  selectors: {
    selectCustomerId: account => account.account.customerId,
    selectUnlock: account => account.unlock,
    selectAccount: account => account,
  }
});

const { reducer, actions, selectors } = accountSlice;

export const {selectUnlock, selectCustomerId, selectAccount } = selectors
export const { setAccount, setUnlock, logOut } = actions