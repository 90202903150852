import { createSlice } from "@reduxjs/toolkit";

export interface MainStates {
    viewCompilation: {
        compilationId: number;
        ownerId: number;
        compilName: string;
        backDestination: string;
    };
}

const initialState: MainStates = {
    viewCompilation: {
        compilationId: -1,
        ownerId: -1,
        compilName: '',
        backDestination: ''
    },
}

export const compilationStatesSlice = createSlice({
    name: "mainStateSlice",
    initialState,
    reducers: {
        openCompilation: (state, action) => {
            state.viewCompilation = action.payload;
        },
    },
    selectors: {
        selectViewCompilation: state => state.viewCompilation,
    }
});

const { reducer, actions, selectors } = compilationStatesSlice;

export const { selectViewCompilation } = selectors
export const { openCompilation } = actions

export default reducer;
