import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { selectCustomerId } from '../slices/accountSlice';

const PrivateRoute = ({ children }) => {
    const customerId = useAppSelector(selectCustomerId);
    return customerId !== -1 ? children : <Navigate replace to="/login" />;
};

export default PrivateRoute;
